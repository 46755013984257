import React from "react";
import ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./assets/styles/theme.css";
import "./assets/styles/layout.css";
import "./assets/styles/buttons.css";
import "./assets/styles/index.css";
import "./assets/styles/services.css";
import "./assets/styles/shop.css";
import "./assets/styles/forms.css";
import "./assets/styles/header.css";
import "./assets/styles/footer.css";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js';


import App from "./App";
import reportWebVitals from "./reportWebVitals";
import HomeScreen from "./screens/HomeScreen";
import AboutScreen from "./screens/AboutScreen";
import ServicesScreen from "./screens/ServicesScreen";
import WebDevScreen from "./screens/services/WebDevScreen";
import CloudScreen from "./screens/services/CloudScreen";
import HardwareScreen from "./screens/services/HardwareScreen";
import NetworkingScreen from "./screens/services/NetworkingScreen";
import ShopScreen from "./screens/ShopScreen";
import ProductScreen from "./screens/ProductScreen";
import CartScreen from "./screens/CartScreen";
import LoginScreen from "./screens/LoginScreen";
import RegisterScreen from "./screens/RegisterScreen";
import ShippingScreen from "./screens/ShippingScreen";
import PrivateRoute from "./components/PrivateRoute";
import PaymentScreen from "./screens/PaymentScreen";
import PlaceOrderScreen from "./screens/PlaceOrderScreen";
import ProfileScreen from "./screens/ProfileScreen";
import OrderListScreen from "./screens/admin/OrderListScreen";
import OrderScreen from "./screens/OrderScreen"; 
import PeachOrderScreen from "./screens/PeachOrderScreen";
import ProductListScreen from "./screens/admin/ProductListScreen";
import ProductEditScreen from "./screens/admin/ProductEditScreen";
import AdminRoute from "./components/AdminRoute";
import UserListScreen from "./screens/admin/UserListScreen";
import UserEditScreen from "./screens/admin/UserEditScreen";
import ContactScreen from "./screens/ContactScreen";
import LayoutScreen from "./screens/LayoutScreen";
import OrderForm from "./screens/OrderForm";
import SalesLeadsListScreen from "./screens/admin/SalesLeadsListScreen";
import SalesLeadsDetailsScreen from "./screens/admin/SalesLeadsDetailScreen.jsx";

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<App />}>
      <Route index={true} path="/" element={<HomeScreen />} />
      <Route index={true} path="/about" element={<AboutScreen />} />
      <Route index={true} path="/services" element={<ServicesScreen />} />
      <Route index={true} path="/services/webdev" element={<WebDevScreen />} />
      <Route index={true} path="/services/cloud" element={<CloudScreen />} />
      <Route
        index={true}
        path="/services/hardware"
        element={<HardwareScreen />}
      />
      <Route
        index={true}
        path="/services/networking"
        element={<NetworkingScreen />}
      />
      <Route index={true} path="/shop" element={<ShopScreen />} />
      <Route index={true} path="/product/:id" element={<ProductScreen />} />
      <Route index={true} path="/cart?" element={<CartScreen />} />
      <Route index={true} path="/signin" element={<LoginScreen />} />
      <Route index={true} path="/register" element={<RegisterScreen />} />
      <Route index={true} path="/contact" element={<ContactScreen />} />
      <Route index={true} path="/layout" element={<LayoutScreen />} />
      <Route index={true} path="/orderform" element={<OrderForm />} />


      <Route path="" element={<PrivateRoute />}>
        <Route index={true} path="/shipping" element={<ShippingScreen />} />
        <Route index={true} path="/placeorder" element={<PlaceOrderScreen />} />
        <Route index={true} path="/payment" element={<PaymentScreen />} />
        <Route index={true} path="/order/:id" element={<OrderScreen />} />
        {/* <Route index={true} path="/order/:id" element={<PeachOrderScreen />} /> */}
        <Route index={true} path="/profile" element={<ProfileScreen />} />
      </Route>
      <Route path="" element={<AdminRoute />}>
        <Route
          index={true}
          path="/admin/orderlist"
          element={<OrderListScreen />}
        />
        <Route
          index={true}
          path="/admin/productlist"
          element={<ProductListScreen />}
        />
        <Route
          index={true}
          path="/admin/product/:id/edit"
          element={<ProductEditScreen />}
        />
        <Route
          index={true}
          path="/admin/userlist"
          element={<UserListScreen />}
        />
        <Route
          index={true}
          path="/admin/user/:id/edit"
          element={<UserEditScreen />}
        />
        <Route
          index={true}
          path="/admin/salesleads"
          element={<SalesLeadsListScreen />}
        />
        <Route
          index={true}
          path="/admin/salesleads/:id"
          element={<SalesLeadsDetailsScreen />}
        />
      </Route>
    </Route>
  )
);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <RouterProvider router={router} />
    </Provider>
  </React.StrictMode>
);

reportWebVitals();
