import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { savePaymentMethod } from "../slices/cartSlice";
import { useNavigate } from "react-router-dom";
import CheckoutSteps from "../components/CheckoutSteps";

const PaymentScreen = () => {
  const [paymentMethod, setPaymentMethod] = useState("PayPal");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  useEffect(() => {
    if (!shippingAddress) {
      navigate("/shipping");
    }
  }, [shippingAddress, navigate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(savePaymentMethod(paymentMethod));
    navigate("/placeorder");
  };
  return (
    <div className="form-container">
      <CheckoutSteps step1 step2 step3 />
      <h1>Payment Method</h1>
      <form className="sign" onSubmit={submitHandler}>
      <fieldset>
          <legend>Select Method</legend>
          <div className="form-group">
            <input
              type="radio"
              id="PayFast"
              name="paymentMethod"
              value="PayFast"
              checked={paymentMethod === "PayFast"}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            <label htmlFor="PayFast">PayFast</label>
          </div>
          <div className="form-group">
            <input
              type="radio"
              id="Yoco"
              name="paymentMethod"
              value="Yoco"
              checked={paymentMethod === "Yoco"}
              onChange={(e) => setPaymentMethod(e.target.value)}
            />
            <label htmlFor="Yoco">Yoco</label>
          </div>
        </fieldset>
        <button type="submit" className="btn-outline glass">
          Continue
        </button>
      </form>
    </div>
  );
};

export default PaymentScreen;
