import {useState , useEffect} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {Form, Button, Row, Col} from 'react-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import FormContainer from '../../components/FormContainer'
import {toast} from 'react-toastify'
import { useUpdateProductMutation,useGetProductDetailsQuery, useUploadProductImageMutation } from '../../slices/productsApiSlice'
import axios from 'axios'


const ProductEditScreen = () => {
    const navigate = useNavigate()
    const {id: productId} = useParams()
    const [name, setName] = useState('')
    const [price, setPrice] = useState(0)
    const [image, setImage] = useState('')
    const [brand, setBrand] = useState('')
    const [category, setCategory] = useState('')
    const [countInStock, setCountInStock] = useState(0)
    const [description, setDescription] = useState('')
    const [uploading, setUploading] = useState(false)
    const {data:product, isLoading, error, refetch} = useGetProductDetailsQuery(productId)
    const [updateProduct, {isLoading: loadingUpdate}] = useUpdateProductMutation()
    const [uploadProductImage, {isLoading: loadingUpload}] = useUploadProductImageMutation()
    useEffect(() => {
        if(product){
            setName(product.name)
            setPrice(product.price)
            setImage(product.image)
            setBrand(product.brand)
            setCategory(product.category)
            setCountInStock(product.countInStock)
            setDescription(product.description)
        }
    }
    , [product])

    const submitHandler = async(e) => {
        e.preventDefault()
        try{
            await updateProduct({_id: productId, name, price, image, brand, category, countInStock, description})
            toast.success('Product updated successfully')
            navigate('/admin/productlist')
        }catch(err){
            toast.error(err?.data?.message || err.error)
        }
    }

    const uploadFileHandler = async(e) => {
        const file = e.target.files[0]
        const formData = new FormData()
        formData.append('image',file)
        setUploading(true)
        try{
            const config = {
                headers:{
                    'Content-Type': 'multipart/form-data'
                }
            }
            const {data} = await axios.post('/api/upload', formData, config)
            setImage(data)
            setUploading(false)
        }catch(err){
            console.log(err)
            setUploading(false)
        }
    }

    return (
    <>
    <Link to='/admin/productlist' className='btn btn-light my-3'>Go Back</Link>
    <FormContainer>
    <h1>Edit Product</h1>
    {loadingUpdate && <Loader/>}
    {isLoading ? <Loader/> : error ? <Message variant='danger'>{error}</Message> :
    <Form onSubmit={submitHandler}>
        <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control type='text' placeholder='Enter name' value={name} onChange={(e) => setName(e.target.value)}></Form.Control>
        </Form.Group>
        <Form.Group controlId='price'>
            <Form.Label>Price</Form.Label>
            <Form.Control type='number' placeholder='Enter price' value={price} onChange={(e) => setPrice(e.target.value)}></Form.Control>
        </Form.Group>
        <Form.Group controlId='image'>
            <Form.Label>Image</Form.Label>
            <Form.Control type='text' placeholder='Enter image url' value={image} onChange={(e) => setImage}></Form.Control>
            <Form.File id='image-file' label='Choose File' custom onChange={uploadFileHandler}></Form.File>
            {uploading && <Loader/>}
        </Form.Group>
        <Form.Group controlId='brand'>
            <Form.Label>Brand</Form.Label>
            <Form.Control type='text' placeholder='Enter brand' value={brand} onChange={(e) => setBrand(e.target.value)}></Form.Control>
        </Form.Group>
        <Form.Group controlId='countInStock'>
            <Form.Label>Count In Stock</Form.Label>
            <Form.Control type='number' placeholder='Enter count in stock' value={countInStock} onChange={(e) => setCountInStock(e.target.value)}></Form.Control>
        </Form.Group>
        <Form.Group controlId='category'>
            <Form.Label>Category</Form.Label>
            <Form.Control type='text' placeholder='Enter category' value={category} onChange={(e) => setCategory(e.target.value)}></Form.Control>
        </Form.Group>
        <Form.Group controlId='description'>
            <Form.Label>Description</Form.Label>
            <Form.Control type='text' placeholder='Enter description' value={description} onChange={(e) => setDescription(e.target.value)}></Form.Control>
        </Form.Group>
        <Button type='submit' variant='primary'>Update</Button>
    </Form>}
    </FormContainer>
    </>
  )
}
export default ProductEditScreen