/* export const BASE_URL =
  process.env.NODE_ENV === "development" ? "http://localhost:5000" : "" */
export const BASE_URL = ""
export const PRODUCTS_URL = `/api/products`
export const USERS_URL = `/api/users`
export const ORDERS_URL = `/api/orders`
export const PAYFAST_URL = `/api/payfast`
export const PRODUCT_DETAIL_URL = `/api/product/:id`
export const UPLOAD_URL = `/api/upload`
export const SERVICES_URL = `/api/services`
export const SALES_URL = `/api/sales`
export const SETTINGS_URL = `/api/settings`;
export const UPLOADS_URL = `/api/upload`;


