export const addDecimals = (num) => {
  return (Math.round(num * 100) / 100).toFixed(2)
}

export const updateCart = (state) => {
  // Calculate items price
  state.itemsPrice = addDecimals(
    state.cartItems.reduce((acc, item) => acc + item.price * item.qty, 0)
  )
  // Calculate shipping price (if oder is over R200 free shipping, else R50 shipping - research South African costs)
  state.shippingPrice = addDecimals(state.itemsPrice > 200 ? 0 : 50)

  // Calculate tax price price (15% VAT)
  state.taxPrice = addDecimals(Number((0.15 * state.itemsPrice).toFixed(2)))

  // Calculate total price
  state.totalPrice = (
    Number(state.itemsPrice) +
    Number(state.shippingPrice) +
    Number(state.taxPrice)
  ).toFixed(2)

  localStorage.setItem("cart", JSON.stringify(state))

  return state
}
