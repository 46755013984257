import React from "react";

const SeervicesSecreen = () => {
  return (
    <>
      <div class="services-container">
        <div class="service-1">
          <div class="service-header">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Coding-Apps-Website-Network-Globe--Streamline-Ultimate.svg"
                clip-path="url(#clip0_138_215)"
              >
                <rect width="32" height="32" fill="#0BFF0B" />
                <path
                  id="Vector"
                  d="M7.33334 16C7.33334 18.2985 8.24644 20.5029 9.87176 22.1283C11.4971 23.7536 13.7015 24.6667 16 24.6667C18.2985 24.6667 20.5029 23.7536 22.1283 22.1283C23.7536 20.5029 24.6667 18.2985 24.6667 16C24.6667 13.7015 23.7536 11.4971 22.1283 9.87176C20.5029 8.24644 18.2985 7.33334 16 7.33334C13.7015 7.33334 11.4971 8.24644 9.87176 9.87176C8.24644 11.4971 7.33334 13.7015 7.33334 16Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M16 7.33334C20.3333 13.1111 20.3333 18.8889 16 24.6667"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_3"
                  d="M16 7.33334C11.6667 13.1111 11.6667 18.8889 16 24.6667"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_4"
                  d="M7.33334 16H24.6667"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_5"
                  d="M15 2C15 2.26521 15.1053 2.51957 15.2929 2.70711C15.4804 2.89464 15.7348 3 16 3C16.2652 3 16.5196 2.89464 16.7071 2.70711C16.8947 2.51957 17 2.26521 17 2C17 1.73479 16.8947 1.48043 16.7071 1.29289C16.5196 1.10536 16.2652 1 16 1C15.7348 1 15.4804 1.10536 15.2929 1.29289C15.1053 1.48043 15 1.73479 15 2Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_6"
                  d="M24.9 6.09995C24.9 6.36516 25.0053 6.61952 25.1929 6.80705C25.3804 6.99458 25.6348 7.09995 25.9 7.09995C26.1652 7.09995 26.4196 6.99458 26.6071 6.80705C26.7947 6.61952 26.9 6.36516 26.9 6.09995C26.9 5.83473 26.7947 5.58037 26.6071 5.39284C26.4196 5.20531 26.1652 5.09995 25.9 5.09995C25.6348 5.09995 25.3804 5.20531 25.1929 5.39284C25.0053 5.58037 24.9 5.83473 24.9 6.09995Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_7"
                  d="M29 16C29 16.2652 29.1053 16.5196 29.2929 16.7071C29.4804 16.8947 29.7348 17 30 17C30.2652 17 30.5196 16.8947 30.7071 16.7071C30.8947 16.5196 31 16.2652 31 16C31 15.7348 30.8947 15.4804 30.7071 15.2929C30.5196 15.1053 30.2652 15 30 15C29.7348 15 29.4804 15.1053 29.2929 15.2929C29.1053 15.4804 29 15.7348 29 16Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_8"
                  d="M24.9 25.9C24.9 26.1653 25.0053 26.4196 25.1929 26.6072C25.3804 26.7947 25.6348 26.9 25.9 26.9C26.1652 26.9 26.4196 26.7947 26.6071 26.6072C26.7947 26.4196 26.9 26.1653 26.9 25.9C26.9 25.6348 26.7947 25.3805 26.6071 25.1929C26.4196 25.0055 26.1652 24.9 25.9 24.9C25.6348 24.9 25.3804 25.0055 25.1929 25.1929C25.0053 25.3805 24.9 25.6348 24.9 25.9Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_9"
                  d="M15 30C15 30.2652 15.1053 30.5196 15.2929 30.7071C15.4804 30.8947 15.7348 31 16 31C16.2652 31 16.5196 30.8947 16.7071 30.7071C16.8947 30.5196 17 30.2652 17 30C17 29.7348 16.8947 29.4804 16.7071 29.2929C16.5196 29.1053 16.2652 29 16 29C15.7348 29 15.4804 29.1053 15.2929 29.2929C15.1053 29.4804 15 29.7348 15 30Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_10"
                  d="M5.10001 25.9C5.10001 26.1653 5.20537 26.4196 5.3929 26.6072C5.58043 26.7947 5.83479 26.9 6.10001 26.9C6.36522 26.9 6.61958 26.7947 6.80711 26.6072C6.99465 26.4196 7.10001 26.1653 7.10001 25.9C7.10001 25.6348 6.99465 25.3805 6.80711 25.1929C6.61958 25.0055 6.36522 24.9 6.10001 24.9C5.83479 24.9 5.58043 25.0055 5.3929 25.1929C5.20537 25.3805 5.10001 25.6348 5.10001 25.9Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_11"
                  d="M1 16C1 16.2652 1.10536 16.5196 1.29289 16.7071C1.48043 16.8947 1.73479 17 2 17C2.26521 17 2.51957 16.8947 2.70711 16.7071C2.89464 16.5196 3 16.2652 3 16C3 15.7348 2.89464 15.4804 2.70711 15.2929C2.51957 15.1053 2.26521 15 2 15C1.73479 15 1.48043 15.1053 1.29289 15.2929C1.10536 15.4804 1 15.7348 1 16Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_12"
                  d="M5.10001 6.09995C5.10001 6.36516 5.20537 6.61952 5.3929 6.80705C5.58043 6.99458 5.83479 7.09995 6.10001 7.09995C6.36522 7.09995 6.61958 6.99458 6.80711 6.80705C6.99465 6.61952 7.10001 6.36516 7.10001 6.09995C7.10001 5.83473 6.99465 5.58037 6.80711 5.39284C6.61958 5.20531 6.36522 5.09995 6.10001 5.09995C5.83479 5.09995 5.58043 5.20531 5.3929 5.39284C5.20537 5.58037 5.10001 5.83473 5.10001 6.09995Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_138_215">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <div class="web-development">Web Development</div>
          </div>
          <div class="responsive-design-e-commerce-sites-domain-registration-domain-hosting-site-administration-site-analytics">
            Responsive Design
            <br />
            E-commerce sites
            <br />
            Domain Registration
            <br />
            Domain Hosting
            <br />
            Site Administration
            <br />
            Site Analytics
          </div>
          <div class="button">
            <div className="btn-outline">Learn More</div>
            <img className="arrow-2" src="arrow-20.svg" />
          </div>
        </div>
        <div class="service-2">
          <div class="service-header">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="User-Network--Streamline-Ultimate.svg"
                clip-path="url(#clip0_139_292)"
              >
                <rect width="32" height="32" fill="#0BFF0B" />
                <path
                  id="Vector"
                  d="M1 4C1 4.39396 1.0776 4.78408 1.22836 5.14805C1.37912 5.51203 1.60011 5.84275 1.87868 6.12132C2.15725 6.39989 2.48797 6.62088 2.85195 6.77164C3.21592 6.9224 3.60604 7 4 7C4.39396 7 4.78408 6.9224 5.14805 6.77164C5.51203 6.62088 5.84275 6.39989 6.12132 6.12132C6.39989 5.84275 6.62088 5.51203 6.77164 5.14805C6.9224 4.78408 7 4.39396 7 4C7 3.60604 6.9224 3.21592 6.77164 2.85195C6.62088 2.48797 6.39989 2.15725 6.12132 1.87868C5.84275 1.60011 5.51203 1.37912 5.14805 1.22836C4.78408 1.0776 4.39396 1 4 1C3.60604 1 3.21592 1.0776 2.85195 1.22836C2.48797 1.37912 2.15725 1.60011 1.87868 1.87868C1.60011 2.15725 1.37912 2.48797 1.22836 2.85195C1.0776 3.21592 1 3.60604 1 4Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M6.42936 5.76129L9.00003 7.62529"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_3"
                  d="M1 28C1 28.394 1.0776 28.7841 1.22836 29.148C1.37912 29.512 1.60011 29.8428 1.87868 30.1213C2.15725 30.3999 2.48797 30.6209 2.85195 30.7716C3.21592 30.9224 3.60604 31 4 31C4.39396 31 4.78408 30.9224 5.14805 30.7716C5.51203 30.6209 5.84275 30.3999 6.12132 30.1213C6.39989 29.8428 6.62088 29.512 6.77164 29.148C6.9224 28.7841 7 28.394 7 28C7 27.606 6.9224 27.2159 6.77164 26.852C6.62088 26.488 6.39989 26.1572 6.12132 25.8787C5.84275 25.6001 5.51203 25.3791 5.14805 25.2284C4.78408 25.0776 4.39396 25 4 25C3.60604 25 3.21592 25.0776 2.85195 25.2284C2.48797 25.3791 2.15725 25.6001 1.87868 25.8787C1.60011 26.1572 1.37912 26.488 1.22836 26.852C1.0776 27.2159 1 27.606 1 28Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_4"
                  d="M6.42936 26.2387L9.00003 24.3733"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_5"
                  d="M25 4C25 4.79565 25.3161 5.55871 25.8787 6.12132C26.4413 6.68393 27.2044 7 28 7C28.7956 7 29.5587 6.68393 30.1213 6.12132C30.6839 5.55871 31 4.79565 31 4C31 3.20435 30.6839 2.44129 30.1213 1.87868C29.5587 1.31607 28.7956 1 28 1C27.2044 1 26.4413 1.31607 25.8787 1.87868C25.3161 2.44129 25 3.20435 25 4Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_6"
                  d="M25.5707 5.76129L23 7.62529"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_7"
                  d="M25 28C25 28.7956 25.3161 29.5587 25.8787 30.1213C26.4413 30.6839 27.2044 31 28 31C28.7956 31 29.5587 30.6839 30.1213 30.1213C30.6839 29.5587 31 28.7956 31 28C31 27.2044 30.6839 26.4413 30.1213 25.8787C29.5587 25.3161 28.7956 25 28 25C27.2044 25 26.4413 25.3161 25.8787 25.8787C25.3161 26.4413 25 27.2044 25 28Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_8"
                  d="M25.5707 26.2387L23 24.3733"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_9"
                  d="M12.5 10.5C12.5 11.4283 12.8687 12.3185 13.5251 12.9749C14.1815 13.6312 15.0717 14 16 14C16.9283 14 17.8185 13.6312 18.4749 12.9749C19.1312 12.3185 19.5 11.4283 19.5 10.5C19.5 9.57175 19.1312 8.68151 18.4749 8.02512C17.8185 7.36875 16.9283 7 16 7C15.0717 7 14.1815 7.36875 13.5251 8.02512C12.8687 8.68151 12.5 9.57175 12.5 10.5Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_10"
                  d="M10 21C10 19.4087 10.6321 17.8825 11.7574 16.7573C12.8826 15.6321 14.4087 15 16 15C17.5913 15 19.1175 15.6321 20.2427 16.7573C21.3679 17.8825 22 19.4087 22 21H10Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_139_292">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <div class="web-development2">Networking</div>
          </div>
          <div class="network-installations-switching-routing-security-wireless-and-wired">
            Network Installations
            <br />
            Switching
            <br />
            Routing
            <br />
            Security
            <br />
            Wireless and Wired
          </div>
          <div class="button">
            <div class="learn-more">Learn More</div>
            <img class="arrow-22" src="arrow-21.svg" />
          </div>
        </div>
        <div class="service-3">
          <div class="service-header">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Cloud-Data-Transfer--Streamline-Ultimate.svg"
                clip-path="url(#clip0_139_303)"
              >
                <rect width="32" height="32" fill="#0BFF0B" />
                <path
                  id="Vector"
                  d="M11.0062 31V17.0113"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M26.0859 20.8083C27.0087 20.5772 27.8695 20.1461 28.6073 19.5456C29.3452 18.9452 29.9423 18.19 30.356 17.3333C30.7699 16.4768 30.9905 15.5397 31.0023 14.5884C31.014 13.6372 30.8167 12.695 30.4241 11.8284C30.0316 10.9618 29.4535 10.1921 28.7307 9.57356C28.0079 8.95504 27.158 8.50287 26.2412 8.249C25.3244 7.99515 24.3629 7.9458 23.4249 8.10445C22.4869 8.26311 21.5952 8.62588 20.8129 9.16715C20.5259 7.62188 19.8784 6.16611 18.9229 4.91812C17.9676 3.67013 16.7313 2.66512 15.3145 1.98475C13.8977 1.30437 12.3404 0.967811 10.7691 1.00242C9.19774 1.03703 7.65676 1.44184 6.27129 2.18393C4.88582 2.92604 3.69497 3.98452 2.79546 5.27336C1.89596 6.56221 1.31319 8.04507 1.09449 9.60148C0.875792 11.1579 1.02734 12.7439 1.53682 14.2308C2.0463 15.7176 2.89934 17.0632 4.02665 18.1584"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_3"
                  d="M15.0029 21.0081L11.0063 17.0113L7.00952 21.0081"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_4"
                  d="M20.998 17.0113V31"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_5"
                  d="M17.0013 27.0032L20.998 31L24.9948 27.0032"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </g>
              <defs>
                <clipPath id="clip0_139_303">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <div class="web-development2">Cloud</div>
          </div>
          <div class="aws-openstack-vps-backups-administration">
            AWS
            <br />
            Openstack
            <br />
            VPS
            <br />
            Backups
            <br />
            Administration
          </div>
          <div class="button">
            <div class="learn-more">Learn More</div>
            <img class="arrow-23" src="arrow-22.svg" />
          </div>
        </div>
        <div class="service-4">
          <div class="service-header">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g
                id="Server-Add--Streamline-Ultimate.svg"
                clip-path="url(#clip0_139_309)"
              >
                <rect width="32" height="32" fill="#0BFF0B" />
                <path
                  id="Vector"
                  d="M17.0013 13.0003H7.00131C5.41 13.0003 3.88388 12.3682 2.75867 11.243C1.63344 10.1177 1.0013 8.59163 1.0013 7.00032C1.0013 5.40903 1.63344 3.88291 2.75867 2.75768C3.88388 1.63247 5.41 1.00033 7.00131 1.00033H23.0013C24.2945 1.00005 25.5532 1.4176 26.5897 2.19076C27.6264 2.96393 28.3855 4.05132 28.7539 5.29092C29.1224 6.53052 29.0804 7.85599 28.6344 9.06981C28.1883 10.2837 27.362 11.3209 26.2787 12.027"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_2"
                  d="M11.0013 25.0003H7.00131C5.41 25.0003 3.88388 24.3681 2.75867 23.2429C1.63344 22.1177 1.0013 20.5916 1.0013 19.0003C1.0013 17.4091 1.63344 15.8829 2.75867 14.7577C3.88388 13.6325 5.41 13.0003 7.00131 13.0003"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_3"
                  d="M14.0013 7.00032H23.0013"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_4"
                  d="M23.0013 31.0003C27.4196 31.0003 31.0013 27.4187 31.0013 23.0003C31.0013 18.582 27.4196 15.0003 23.0013 15.0003C18.5831 15.0003 15.0013 18.582 15.0013 23.0003C15.0013 27.4187 18.5831 31.0003 23.0013 31.0003Z"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_5"
                  d="M23.0013 19.0003V27.0003"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_6"
                  d="M19.0013 23.0003H27.0013"
                  stroke="black"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  id="Vector_7"
                  d="M7.0013 7.50032C6.72516 7.50032 6.5013 7.27647 6.5013 7.00032C6.5013 6.72419 6.72516 6.50032 7.0013 6.50032"
                  stroke="black"
                  stroke-width="1.5"
                />
                <path
                  id="Vector_8"
                  d="M7.0013 7.50032C7.27744 7.50032 7.5013 7.27647 7.5013 7.00032C7.5013 6.72419 7.27744 6.50032 7.0013 6.50032"
                  stroke="black"
                  stroke-width="1.5"
                />
                <path
                  id="Vector_9"
                  d="M7.0013 19.5003C6.72516 19.5003 6.5013 19.2765 6.5013 19.0003C6.5013 18.7241 6.72516 18.5003 7.0013 18.5003"
                  stroke="black"
                  stroke-width="1.5"
                />
                <path
                  id="Vector_10"
                  d="M7.0013 19.5003C7.27744 19.5003 7.5013 19.2765 7.5013 19.0003C7.5013 18.7241 7.27744 18.5003 7.0013 18.5003"
                  stroke="black"
                  stroke-width="1.5"
                />
              </g>
              <defs>
                <clipPath id="clip0_139_309">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>

            <div class="web-development2">Hardware</div>
          </div>
          <div class="sales-installations-upgrades-management">
            Sales
            <br />
            Installations
            <br />
            Upgrades
            <br />
            Management
          </div>
          <div class="button">
            <div class="learn-more">Learn More</div>
            <img class="arrow-24" src="arrow-23.svg" />
          </div>
        </div>
      </div>
    </>
  );
};

export default SeervicesSecreen;
