import React, { useState } from "react";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useNavigate, useParams } from "react-router-dom";
import { useSelector } from "react-redux";
import { useGetServicesQuery } from "../slices/servicesApiSlice";

// creating functional component ans getting props from app.js and destucturing them
const OrderForm = () => {
  //conditional form fields depending on service type
  const [serviceType, setServiceType] = useState("");
  const [title, setTitles] = useState({});

  const navigate = useNavigate();
  const params = useParams();
  const cart = useSelector((state) => state.cart);

  const submitHandler = async (e) => {
    e.preventDefault();
    navigate("/orderagent");
  };

    

  const submitFormData = (e) => {
    e.preventDefault();
    // checking if value of first name and last name is empty show error else take to next step
  };


  return (
    // removed container to free up space for the form in responsive view
    <div>
      <div id="step_one_order" title={title}>
        <form onSubmit={submitFormData}>
          <div className="form-group">
            <label htmlFor="serviceType">Service Type</label>
            <select
              className="form-control"
              id="serviceType"
              value={serviceType}
              onChange={(e) => setServiceType(e.target.value)}
            >
              <option value="select">Select</option>
              <option value="category">Category</option>
              <option value="frequency">Frequency</option>
              <option value="type">Type</option>
            </select>
          </div>
        </form>
      </div>
    </div>
  );
};
export default OrderForm;
