import { useCreateSalesLeadMutation } from "../slices/salesApiSlice";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { FaArrowRight } from "react-icons/fa";
import ReactGA from 'react-ga4';

ReactGA.initialize('G-W39W26SYSM'); // Replace with your tracking ID
ReactGA.send("pageview");

const ContactScreen = () => {
  const [name, setName] = useState("");
  const [company, setCompany] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [category, setCategory] = useState("");
  const [message, setMessage] = useState("");
  const [createSalesLead, { isLoading, error }] = useCreateSalesLeadMutation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const submitHandler = async (e) => {
    e.preventDefault();
    try {
      const res = await createSalesLead({
        name,
        company,
        email,
        phone,
        category,
        message,
      }).unwrap();
      dispatch({ ...res });
      // toast.success("Form submitted successfully");
      navigate("/");
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <div className="form-container">
          <h1>Contact Us</h1>
          <form className="sign" onSubmit={submitHandler}>
            <fieldset>
              <input
                type="text"
                id="fullName"
                className="input"
                placeholder="Enter name"
                value={name}
                onChange={(e) => setName(e.target.value)}
                required
              />
            </fieldset>
            <fieldset>
              <input
                type="text"
                id="company"
                className="input"
                placeholder="Company (Optional)"
                value={company}
                onChange={(e) => setCompany(e.target.value)}
              />
            </fieldset>
            <fieldset>
              <input
                type="email"
                id="email"
                className="input"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                required
              />
            </fieldset>
            <fieldset>
              <input
                type="text"
                id="phone"
                className="input"
                placeholder="Phone"
                value={phone}
                onChange={(e) => setPhone(e.target.value)}
              />
            </fieldset>
            <fieldset>
              <input
                type="text"
                id="category"
                className="input"
                placeholder="Category"
                value={category}
                onChange={(e) => setCategory(e.target.value)}
              />
            </fieldset>
            <fieldset>
              <textarea
                id="message"
                placeholder="Message"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
            </fieldset>
            <button type="submit" disabled={isLoading} className="btn-outline" onClick={submitHandler}>
              Sumbit Enquiry <FaArrowRight />
            </button>
            {isLoading && <Loader />}
          </form>
        </div>
      )}
    </>
  );
};
export default ContactScreen;
