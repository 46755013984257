import React from "react";
import { Message } from "@icon-park/react";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga4';

ReactGA.initialize('G-W39W26SYSM'); // Replace with your tracking ID
ReactGA.send("pageview");

const HomeScreen = () => {
  return (
    <div className="hero_wrapper">

      <div className="btn-outline glass">
            <Link to="/contact">GET IN TOUCH</Link>
            <Message theme="outline" size="24" />
          </div>
    </div>
  );
};

export default HomeScreen;
