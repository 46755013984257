import React from "react";
import { Link } from "react-router-dom";
import { CircleDoubleRight, ArrowCircleRight } from "@icon-park/react";

const NetworkingScreen = () => {
  return (
    <>
      <h1 class="header-webdev">Networking</h1>
      <div class="container">
        <div class="card-webdev">
          <div class="btn-webdev-top">
            <h3 class="header-card-webdev">Routing and Switching</h3>
          </div>
          <ul class="list-card-webdev">
            <li>
              <CircleDoubleRight />
              Layer 2 and Layer 3 Switching
            </li>
            <li>
              <CircleDoubleRight />
              Access, Distribution and Core Layers
            </li>
            <li>
              <CircleDoubleRight />
              Network Design
            </li>
            <li>
              <CircleDoubleRight />
              Wireless and Wired{" "}
            </li>
          </ul>

          <div className="btn-outline">
            <Link>Learn More</Link>
            <ArrowCircleRight />
          </div>
        </div>

        <div class="card-webdev">
          <div class="btn-webdev-top">
            <h3 class="header-card-webdev">Internet</h3>
          </div>
          <ul class="list-card-webdev">
            <li>
              <CircleDoubleRight /> ISP connectivity
            </li>
            <li>
              <CircleDoubleRight />
              SD-WAN
            </li>
            <li>
              <CircleDoubleRight />
              VPN Connectivity
            </li>
            <li>
              <CircleDoubleRight />
              APN and Mobile Connectivity
            </li>
          </ul>

          <div className="btn-outline">
            <Link>Learn More</Link>
            <ArrowCircleRight />
          </div>
        </div>

        <div class="card-webdev">
          <div class="btn-webdev-top">
            <h3 class="header-card-webdev">IP Telephony</h3>
          </div>
          <ul class="list-card-webdev">
            <li>
              <CircleDoubleRight />
              End-point connectivity
            </li>
            <li>
              <CircleDoubleRight />
              Pabx
            </li>
            <li>
              <CircleDoubleRight />
              VoIP callling
            </li>
          </ul>

          <div className="btn-outline">
            <Link>Learn More</Link>
            <ArrowCircleRight />
          </div>
        </div>
        <div class="card-webdev">
          <div class="btn-webdev-top">
            <h3 class="header-card-webdev">Security</h3>
          </div>
          <ul class="list-card-webdev">
            <li>
              <CircleDoubleRight />
              Firewall Installations and setup
            </li>
            <li>
              <CircleDoubleRight />
              Anti-virus
            </li>
            <li>
              <CircleDoubleRight />
              Security Audits
            </li>
          </ul>

          <div className="btn-outline">
            <Link>Learn More</Link>
            <ArrowCircleRight />
          </div>
        </div>
      </div>
    </>
  );
};

export default NetworkingScreen;
