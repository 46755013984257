import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useCreateOrderMutation } from "../slices/ordersApiSlice";
import { clearCartItems } from "../slices/cartSlice";
import CheckoutSteps from "../components/CheckoutSteps";

const PlaceOrderScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const [createOrder, { isLoading, error }] = useCreateOrderMutation();

  useEffect(() => {
    if (!cart.shippingAddress) {
      navigate("/shipping");
    } else if (!cart.paymentMethod) {
      navigate("/payment");
    }
  }, [cart.shippingAddress.address, cart.paymentMethod, navigate]);

  const placeOrderHandler = async () => {
    try {
      const res = await createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        taxPrice: cart.taxPrice,
        shippingPrice: cart.shippingPrice,
        totalPrice: cart.totalPrice,
        
      }).unwrap();
      dispatch(clearCartItems());
      navigate(`/order/${res._id}`);
      console.log(res);
    } catch (err) {
      toast.error(err);
    }
  };

  return (
    <>
      <div className="checkout_steps">
        <CheckoutSteps step1 step2 step3 step4 />
      </div>
      <div className="order">
        <div className="order_details">
          <div>
            <h2>Shipping</h2>
            <p>
              <strong>Address:</strong>
              {cart.shippingAddress.address1}, {cart.shippingAddress.address2},{" "}
              {cart.shippingAddress.city}, {cart.shippingAddress.postalCode},{" "}
              {cart.shippingAddress.country}
            </p>
          </div>
          <div>
            <h2>Payment Method</h2>
            <strong>Method: </strong>
            {cart.paymentMethod}
          </div>
          <div>
            <h2>Order Items</h2>
            {cart.cartItems.length === 0 ? (
              <Message>Your cart is empty</Message>
            ) : (
              <>
                {cart.cartItems.map((item, index) =>
                  item.type === "Service" ? (
                    <div class="cart_items" key={item._id}>
                      <h5>{item.description}</h5>
                      <ul className="text_box">
                        {item.features_free.map((feature) => (
                          <li>{feature}</li>
                        ))}
                      </ul>
                    </div>
                  ) : (
                    <div className="order_items" key={index}>
                      <img
                        src={item.image}
                        alt={item.name}
                        width="80px"
                        height="auto"
                      />
                      <Link to={`/product/${item._id}`}>{item.name}</Link>
                      {item.qty} x R{item.price} = R{item.qty * item.price}
                    </div>
                  )
                )}
              </>
            )}
          </div>
        </div>
        <div className="order_summary">
          <h2>Order Summary</h2>

          <p>Items R{cart.itemsPrice}</p>
          <p>Shipping R{cart.shippingPrice}</p>
          <p>Tax R{cart.taxPrice}</p>
          <p>Total R{cart.totalPrice}</p>
          {error && <Message variant="danger">{error}</Message>}
          <button
            className="btn-outline glass"
            disabled={cart.cartItems === 0}
            onClick={placeOrderHandler}
          >
            Place Order
          </button>
          {isLoading && <Loader />}
        </div>
      </div>
    </>
  );
};

export default PlaceOrderScreen;
