import { apiSlice } from "./apiSlice"
import { ORDERS_URL } from "../constants"

export const ordersApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getOrders: builder.query({
      query: () => ({
        url: `${ORDERS_URL}`,
        method: "GET",
      }),
    }),
    getOrderById: builder.query({
      query: (id) => ({
        url: `${ORDERS_URL}/${id}`,
        method: "GET",
      }),
    }),
    createOrder: builder.mutation({
      query: (order) => ({
        url: ORDERS_URL,
        method: "POST",
        body: { ...order },
      }),
    }),
    getOrderDetails: builder.query({
      query: (id) => ({
        url: `${ORDERS_URL}/${id}`,
        method: "GET",
      }),
      keepUnusedDataFor: 5,
    }),
    updateOrder: builder.mutation({
      query: ({ id, data }) => ({
        url: `${ORDERS_URL}/${id}`,
        method: "PUT",
        body: data,
      }),
    }),
    deleteOrder: builder.mutation({
      query: (id) => ({
        url: `${ORDERS_URL}/${id}`,
        method: "DELETE",
      }),
    }),
    createPayment: builder.mutation({
      query: (id) => ({
        url: `${ORDERS_URL}/payfast/`,
        method: "POST",
        body: {},
      }),
    }),
    createPeachPayments: builder.mutation({
      query: (id) => ({
        url: `${ORDERS_URL}/peachpayments/`,
        method: "POST",
        body: {},
      }),
    }),
    getMyOrders: builder.query({
      query: () => ({
        url: `${ORDERS_URL}/mine`,
        method: "GET",
      }),
      keepUnusedDataFor: 5,
    }),
   
    deliverOrder: builder.mutation({
      query: (id) => ({
        url: `${ORDERS_URL}/${id}/deliver`,
        method: "PUT",
      }),
    }),
  }),
})
export const {
  useCreateOrderMutation,
  useDeleteOrderMutation,
  useGetOrderByIdQuery,
  useGetOrdersQuery,
  useUpdateOrderMutation,
  useGetOrderDetailsQuery,
  useCreatePaymentMutation,
  useGetMyOrdersQuery,
  useDeliverOrderMutation,
  useCreatePeachPaymentsMutation,
} = ordersApiSlice
