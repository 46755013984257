import React from "react";
import { Link } from "react-router-dom";
import Rating from "../components/Rating";
import hp1 from "../assets/images/hp1.png";
import hp2 from "../assets/images/hp2.png";
import hp3 from "../assets/images/hp3.png";
import hp4 from "../assets/images/hp4.png";
import hp5 from "../assets/images/hp5.png";
import yoga from "../assets/images/yoga.avif";
import zenflip from "../assets/images/zenflip.png";
import dell from "../assets/images/dell.avif";

const LayoutScreen = () => {
  const displayInMainBox = (e) => {
    // get the image source
    // set the image source to the main image box
    console.log(e.target.src);
    document.getElementById(
      "mainImageBox"
    ).innerHTML = `<img src=${e.target.src} height="auto" width="550px" alt="placeholder" />`;
    //toggle the active class
    e.target.classList.toggle("img-active");

    // remove the active class from the other images
    const imageLinks = document.querySelectorAll("#imgCol a img");
    imageLinks.forEach((image) => {
      if (image !== e.target) {
        image.classList.remove("img-active");
      }
    });
  };
  return (
    <div className="page_wrapper">
      <div className="row ">
        {/* <h3>Row1</h3> */}
        <div className="row">
          {/* <h3>Col1R1</h3> */}
          <div className="column2" id="mainImageBox">
            <img src={hp1} height="auto" width="550px" alt="placeholder" />
          </div>
          <div className="column2" id="imgCol">
            <Link onClick={displayInMainBox}>
              {" "}
              <img
                className="img-active"
                src={hp1}
                alt="placeholder"
                height="100px"
                width="100px"
              />
            </Link>
            <Link onClick={displayInMainBox}>
              {" "}
              <img src={hp2} alt="placeholder" height="100px" width="100px" />
            </Link>
            <Link onClick={displayInMainBox}>
              {" "}
              <img src={hp3} alt="placeholder" height="100px" width="100px" />
            </Link>
            <Link onClick={displayInMainBox}>
              <img src={hp4} alt="placeholder" height="100px" width="100px" />
            </Link>
            <Link onClick={displayInMainBox}>
              {" "}
              <img src={hp5} alt="placeholder" height="100px" width="100px" />
            </Link>
          </div>
        </div>
        <div className="column column_product_description">
          <h2>HP S-series 3000</h2>
          <p color="black" className="product-detail-description">
            <h3>Product Specification</h3>
            <table className="product_spec_table">
              <tr>
                <td>Brand</td>
                <td>HP</td>
              </tr>
              <tr>
                <td>Model</td>
                <td>HP 3000</td>
              </tr>
              <tr>
                <td>Processor</td>
                <td>Intel Core i7</td>
              </tr>
              <tr>
                <td>RAM</td>
                <td>16GB</td>
              </tr>
              <tr>
                <td>Storage</td>
                <td>1TB SSD</td>
              </tr>
              <tr>
                <td>Graphics</td>
                <td>NVIDIA GeForce MX250</td>
              </tr>
              <tr>
                <td>Display</td>
                <td>15.6-inch Full HD</td>
              </tr>
              <tr>
                <td>Operating System</td>
                <td>Windows 10 Home</td>
              </tr>
              <tr>
                <td>Weight</td>
                <td>1.98Kg</td>
              </tr>
              <tr>
                <td>Battery</td>
                <td>3-cell Li-ion</td>
              </tr>
            </table>
          </p>
          <h2>R 5,999</h2>
          <Rating value={4.5} text="200 reviews" />
          <div
            className="color-picker"
            style={{ marginTop: "8px", marginBottom: "16px" }}
          >
            <button
              className="color-button"
              style={{ backgroundColor: "red", height: "20px", width: "20px" }}
            ></button>
            <button
              className="color-button"
              style={{ backgroundColor: "blue", height: "20px", width: "20px" }}
            ></button>
            <button
              className="color-button"
              style={{
                backgroundColor: "green",
                height: "20px",
                width: "20px",
              }}
            ></button>
            <button
              className="color-button"
              style={{
                backgroundColor: "yellow",
                height: "20px",
                width: "20px",
              }}
            ></button>
            <button
              className="color-button"
              style={{
                backgroundColor: "purple",
                height: "20px",
                width: "20px",
              }}
            ></button>
          </div>

          <div className="row">
            <button className="btn-primary">Add to Cart</button>
            <button className="button-86">Buy Now</button>
          </div>
        </div>
      </div>
      <h3>Recommended Products</h3>

      <div className="row row_recommended_products">
        <img src={yoga} alt="placeholder" height="100px" width="100px" />
        <img src={zenflip} alt="placeholder" height="100px" width="100px" />
        <img src={dell} alt="placeholder" height="100px" width="100px" />
        <img src={hp4} alt="placeholder" height="100px" width="100px" />
        <img src={hp5} alt="placeholder" height="100px" width="100px" />
      </div>
    </div>
  );
};

export default LayoutScreen;
