import React from 'react'
import { Card } from 'react-bootstrap'
import {Link} from 'react-router-dom'
import Rating from './Rating'

const Product = ({product}) => {
  return (
    <div className='product_card_wrapper'>
        <Link to={`/product/${product._id}`}>
            <Card.Img src={product.image} variant='top'/>
        </Link>
        <div className="product_card_body">
            <Link to={`/product/${product._id}`}>
                <div className="product_card_title">
                    <strong>{product.name}</strong>
                </div>
            </Link>
            <div className="product_card_rating">
                <Rating value={product.rating} text={`${product.numReviews} reviews`}/>
            </div>
            <div className="product_card_price">

                R{product.price}
            </div>
        </div>
     </div>
  )
}

export default Product