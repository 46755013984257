import { useState } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import Rating from "../components/Rating";
import Loader from "../components/Loader";
import Message from "../components/Message";
import { useGetProductDetailsQuery } from "../slices/productsApiSlice";
import { addToCart } from "../slices/cartSlice";
import hp1 from "../assets/images/hp1.png";
import hp2 from "../assets/images/hp2.png";
import hp3 from "../assets/images/hp3.png";
import hp4 from "../assets/images/hp4.png";
import hp5 from "../assets/images/hp5.png";

const ProductScreen = () => {
  const { id: productId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    data: product,
    isLoading,
    error,
  } = useGetProductDetailsQuery(productId);
  const [qty, setQty] = useState(1);

  const addToCartHandler = () => {
    dispatch(addToCart({ ...product, qty }));
    navigate("/cart");
  };

  const displayInMainBox = (e) => {
    const mainImageBox = document.getElementById("mainImageBox");
    mainImageBox.src = e.target.src;
    e.target.classList.toggle("img-active");

    const imageLinks = document.querySelectorAll("#imgCol img");
    imageLinks.forEach((image) => {
      if (image !== e.target) {
        image.classList.remove("img-active");
      }
    });
  };

  return (
    <div className="page_wrapper">
      <Link to="/shop">Go Back</Link>

      {isLoading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error?.data?.message || error.error}</Message>
      ) : (
        <div className="product-detail-grid">
          <div className="image-gallery">
            <img
              id="mainImageBox"
              src={product.image}
              className="main-image"
              alt={product.name}
            />
            <div id="imgCol" className="thumbnail-gallery">
              <img
                className="img-active"
                src={hp1}
                alt="thumbnail"
                onClick={displayInMainBox}
              />
              <img src={hp2} alt="thumbnail" onClick={displayInMainBox} />
              <img src={hp3} alt="thumbnail" onClick={displayInMainBox} />
              <img src={hp4} alt="thumbnail" onClick={displayInMainBox} />
              <img src={hp5} alt="thumbnail" onClick={displayInMainBox} />
            </div>
          </div>
          <div className="product-info">
            <h2>{product.name}</h2>
            <p>{product.description}</p>
            <h3>Product Specification</h3>
            <table className="product_spec_table">
              <tbody>
                <tr>
                  <td>Brand</td>
                  <td>HP</td>
                </tr>
                <tr>
                  <td>Model</td>
                  <td>HP 3000</td>
                </tr>
                <tr>
                  <td>Processor</td>
                  <td>Intel Core i7</td>
                </tr>
                <tr>
                  <td>RAM</td>
                  <td>16GB</td>
                </tr>
                <tr>
                  <td>Storage</td>
                  <td>1TB SSD</td>
                </tr>
                <tr>
                  <td>Graphics</td>
                  <td>NVIDIA GeForce MX250</td>
                </tr>
                <tr>
                  <td>Display</td>
                  <td>15.6-inch Full HD</td>
                </tr>
                <tr>
                  <td>Operating System</td>
                  <td>Windows 10 Home</td>
                </tr>
                <tr>
                  <td>Weight</td>
                  <td>1.98Kg</td>
                </tr>
                <tr>
                  <td>Battery</td>
                  <td>3-cell Li-ion</td>
                </tr>
              </tbody>
            </table>
            <h2>{product.price}</h2>
            <Rating value={4.5} text="200 reviews" />
            <div className="color-picker">
              <button
                className="color-button"
                style={{ backgroundColor: "red" }}
              ></button>
              <button
                className="color-button"
                style={{ backgroundColor: "blue" }}
              ></button>
              <button
                className="color-button"
                style={{ backgroundColor: "green" }}
              ></button>
              <button
                className="color-button"
                style={{ backgroundColor: "yellow" }}
              ></button>
              <button
                className="color-button"
                style={{ backgroundColor: "purple" }}
              ></button>
            </div>

            <div className="actions">
              <button
                className="btn"
                disabled={product.countInStock === 0}
                onClick={addToCartHandler}
              >
                Add to Cart
              </button>
              <button className="btn-outline">Buy Now</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ProductScreen;
