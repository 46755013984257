import {  useEffect } from "react";
import { toast } from "react-toastify";
import { Link, useParams } from "react-router-dom";
import Message from "../components/Message";
import Loader from "../components/Loader";
import { useSelector } from "react-redux";
import {
  useGetOrderDetailsQuery,
  useCreatePaymentMutation,
  useDeliverOrderMutation,
} from "../slices/ordersApiSlice";


const OrderScreen = () => {
  const { id: orderId } = useParams();
  const { userInfo } = useSelector((state) => state.auth); // get user info from auth slice

  const {
    data: order,
    refetch,
    isLoading,
    error,
  } = useGetOrderDetailsQuery(orderId);
  const [createPayment, { isLoading: loadingPay }] = useCreatePaymentMutation();
  const [deliverOrder, { isLoading: loadingDeliver }] =
    useDeliverOrderMutation();

  useEffect(() => {
    const script = document.createElement("script");
    script.src = "https://www.payfast.co.za/onsite/engine.js";
    script.async = true;
    document.body.appendChild(script);
    return () => {
      // cleanup the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, []);

  const payFastHandler = async () => {
    try {
      // Request to backend to get the pfParamString
      const result = await createPayment(orderId).unwrap();
      // Initiate the onsite payment
      window.payfast_do_onsite_payment({ uuid: result.identifier });
      // Refetch the order to update the payment status
      refetch();
      toast.success("Payment Successful");
    } catch (err) {
      console.error("Error during payment process:", err);
      toast.error(
        err?.response?.data?.message || err.message || "An error occurred"
      );
    }
  };

  const deliverOrderHandler = async (orderId) => {
    try {
      await deliverOrder(orderId);
      refetch();
      // toast.success(res.message)
    } catch (err) {
      toast.error(err?.data?.message || err.error);
    }
  };

  return isLoading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error}</Message>
  ) : (
    <>
      <h1>Order {order._id}</h1>
      <div className="order">
        <div className="order_details">
          <h2>Shipping</h2>
          <p>
            <strong>Name: </strong>
            {order.user.name}
          </p>
          <p>
            <strong>Email: </strong>
            <a href={`mailto:${order.user.email}`}>{order.user.email}</a>
          </p>
          <p>
            <strong>Address:</strong>
            {order.shippingAddress.address1}, {order.shippingAddress.address2},{" "}
            {order.shippingAddress.city}, {order.shippingAddress.postalCode},{" "}
            {order.shippingAddress.country}
          </p>
          {order.isDelivered ? (
            <Message variant="success">
              Delivered on {order.deliveredAt}
            </Message>
          ) : (
            <Message variant="danger">Not Delivered</Message>
          )}
          <h2>Payment Method</h2>
          <p>
            <strong>Method:</strong>
            {order.paymentMethod}
          </p>
          {order.isPaid ? (
            <Message variant="success">Paid on {order.paidAt}</Message>
          ) : (
            <Message variant="danger">Not Paid</Message>
          )}
          <h2>Order Items</h2>
          {order.orderItems.length === 0 ? (
            <Message>Order is empty</Message>
          ) : (
            <>
              {order.orderItems.map((item, index) => (
                <div key={index}>
                  <image src={item.image} alt={item.name} fluid rounded />
                  <Link to={`/product/${item.product}`}>{item.name}</Link>
                  {item.qty} x R{item.price} = R{item.qty * item.price}
                </div>
              ))}
            </>
          )}
        </div>
        <div className="order_summary">
          <h2>Order Summary</h2>
          <p>R{order.itemsPrice}</p>
          <p>Items</p>
          <p>Shipping</p>
          <p>R{order.shippingPrice}</p>
          <p>Tax</p>
          <p>R{order.taxPrice}</p>
          <p>Total</p>
          <p>R{order.totalPrice}</p>
          {!order.isPaid && (
            <button
              className="btn-outline glass"
              disabled={order.orderItems.length === 0}
              onClick={payFastHandler}
            >
              Pay Now
            </button>
          )}
          {isLoading && <Loader />}
          {userInfo &&
            userInfo.isAdmin &&
            order.isPaid &&
            !order.isDelivered && (
              <button
                className="btn-outline glass"
                onClick={() => deliverOrderHandler(order._id)}
              >
                Mark As Delivered
              </button>
            )}
        </div>
      </div>
    </>
  );
};

export default OrderScreen;
