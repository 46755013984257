import React from 'react'
import { Link } from 'react-router-dom'
import {CircleDoubleRight, ArrowCircleRight } from "@icon-park/react";


const HardwareScreen = () => {
  return (
    <>
    <h1 class="header-webdev">Hardware Infrastructure </h1>
      <div class="container">
      <div class="card-webdev">
          <div class="btn-webdev-top">
            <h3 class="header-card-webdev">Servers</h3>
          </div>
          <ul class="list-card-webdev">
            <li><CircleDoubleRight/>End-point connectivity</li>
            <li><CircleDoubleRight/>Pabx</li>
            <li><CircleDoubleRight/>VoIP callling</li>
          </ul>

          <div className="btn-outline">
            <Link>Learn More</Link>
            <ArrowCircleRight />
          </div>
        </div>

        <div class="card-webdev">
          <div class="btn-webdev-top">
            <h3 class="header-card-webdev">Storage </h3>
          </div>
          <ul class="list-card-webdev">
            <li><CircleDoubleRight/>End-point connectivity</li>
            <li><CircleDoubleRight/>Pabx</li>
            <li><CircleDoubleRight/>VoIP callling</li>
          </ul>

          <div className="btn-outline">
            <Link>Learn More</Link>
            <ArrowCircleRight />
          </div>
        </div>
      </div>
    </>
  )
}

export default HardwareScreen