import { SERVICES_URL } from "../constants";
import { apiSlice } from "./apiSlice";

export const servicesApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getServices: builder.query({
      query: () => ({
        url: SERVICES_URL,
      }),
      providesTags: ["Services"],
      keepUnusedDataFor: 5,
    }),

    getServiceDetails: builder.query({
      query: (serviceId) => ({
        url: `${SERVICES_URL}/${serviceId}`,
      }),
      keepUnusedDataFor: 5,
    }),

    createService: builder.mutation({
      query: () => ({
        url: SERVICES_URL,
        method: "POST",
      }),
      invalidatesTags: ["Service"],
    }),

    updateService: builder.mutation({
      query: (data) => ({
        url: `${SERVICES_URL}/${data.serviceId}`,
        method: "PUT",
        body: data,
      }),
      invalidatesTags: ["Services"],
    }),

    deleteService: builder.mutation({
      query: (serviceId) => ({
        url: `${SERVICES_URL}/${serviceId}`,
        method: "DELETE",
      }),
    }),
  }),
});

export const {
  useGetServicesQuery,
  useGetServiceDetailsQuery,
  useCreateServiceMutation,
  useUpdateServiceMutation,
  useDeleteServiceMutation,
} = servicesApiSlice;
