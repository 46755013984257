import { Facebook, Instagram, Twitter } from "@icon-park/react";
import { Link } from "react-router-dom";
import datablox_logo from "../assets/images/datablox_logo.png";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="bg-dark text-white p-3">
      <div className="container">
        <div className="row d-flex justify-content-center">
          {/* Menu Links */}
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <ul className="list-unstyled d-flex flex-column flex-md-row justify-content-md-around text-center text-md-left">
              <li className="nav-item mx-2">
                <Link
                  className="nav-link active text-white"
                  aria-current="page"
                  to="/"
                >
                  Home
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link className="nav-link text-white" to="/about">
                  About
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link className="nav-link text-white" to="/services">
                  Services
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link className="nav-link text-white" to="/contact">
                  Contact
                </Link>
              </li>
            </ul>
          </div>
          {/* Services Links */}
          <div className="col-12 col-md-4 mb-3 mb-md-0">
            <ul className="list-unstyled d-flex flex-column flex-md-row justify-content-md-around text-center text-md-left">
              <li className="nav-item mx-2">
                <Link
                  className="nav-link text-white nowrap"
                  to="/services/webdev"
                >
                  Web Development
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link className="nav-link text-white nowrap" to="/services/cloud">
                  Cloud Services
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link
                  className="nav-link text-white nowrap"
                  to="/services/networking"
                >
                  Networking
                </Link>
              </li>
              <li className="nav-item mx-2">
                <Link
                  className="nav-link text-white nowrap"
                  to="/services/hardware"
                >
                  Hardware
                </Link>
              </li>
            </ul>
          </div>
          {/* Social Media Links */}
          <div className="col-12 col-md-4 d-flex justify-content-center justify-content-md-end align-items-center">
            <Link className="mx-2" to="#">
              <Facebook theme="filled" size="24" fill="#fff" />
            </Link>
            <Link className="mx-2" to="#">
              <Instagram theme="filled" size="24" fill="#fff" />
            </Link>
            <Link className="mx-2" to="#">
              <Twitter theme="filled" size="24" fill="#fff" />
            </Link>
          </div>
          {/* Logo */}
          {/* <div className="col-12 col-md-4 d-flex justify-content-center mb-3 mb-md-0">
            <Link to="/">
              <img
                src={datablox_logo}
                width="150px"
                height="100px"
                alt="datablox-logo"
                className="img-fluid"
              />
            </Link>
          </div> */}
        </div>

        <div className="text-center mt-3">
          <small>{currentYear} &copy; Datablox PTY(Ltd)</small>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
