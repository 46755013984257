import React from "react";
import { Link, useNavigate } from "react-router-dom";
import Message from "../components/Message";
import { useDispatch, useSelector } from "react-redux";
import { addToCart, removeFromCart } from "../slices/cartSlice";
import { Delete } from "@icon-park/react";

const CartScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;

  const addToCartHandler = (product, qty) => {
    dispatch(addToCart({ ...product, qty }));
  };
  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };
  const checkoutHandler = () => {
    navigate("/signin?redirect=/shipping");
  };

  return (
    <div className="container my-5">
      <div className="row d-flex justify-content-center">
        <div className="col-md-12">
          <h1 className="mb-4">Shopping Cart</h1>
          {cartItems.length === 0 ? (
            <Message>
              Your cart is empty <Link to="/">Go Back</Link>
            </Message>
          ) : (
            <ul className="list-group list-group-flush">
              {cartItems.map((item) => (
                <li className="list-group-item py-3" key={item._id}>
                  <div className="row align-items-center">
                    <div className="col-md-2">{item.name}</div>
                    <div className="col-md-3">{item.description}</div>
                    <div className="col-md-2">
                      <select
                        className="form-select"
                        value={item.qty}
                        onChange={(e) =>
                          addToCartHandler(item, Number(e.target.value))
                        }
                      >
                        {[...Array(item.countInStock).keys()].map((x) => (
                          <option key={x + 1} value={x + 1}>
                            {x + 1}
                          </option>
                        ))}
                      </select>
                    </div>
                    <div className="col-md-2 text-center">R{item.price}</div>
                    <div className="col-md-2 text-center">
                      <button
                        type="button"
                        className="btn btn-light"
                        onClick={() => removeFromCartHandler(item._id)}
                      >
                        <Delete />
                      </button>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div>
        <div className="col-md-12">
          <div className="card">
            <ul className="list-group list-group-flush">
              <li className="list-group-item d-flex justify-content-between">
                <h2>Subtotal</h2>
                <h3>
                  R
                  {cartItems
                    .reduce((acc, item) => acc + item.qty * item.price, 0)
                    .toFixed(2)}
                </h3>
              </li>
              <li className="list-group-item">
                <button
                  type="button"
                  className="btn-outline glass w-100"
                  disabled={cartItems.length === 0}
                  onClick={checkoutHandler}
                >
                  Proceed To Checkout
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
export default CartScreen;
