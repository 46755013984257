import {useState , useEffect} from 'react'
import {Link, useNavigate, useParams} from 'react-router-dom'
import {Form, Button, Row, Col} from 'react-bootstrap'
import Message from '../../components/Message'
import Loader from '../../components/Loader'
import FormContainer from '../../components/FormContainer'
import {toast} from 'react-toastify'
import {useUpdateUserMutation,useGetUserDetailsQuery } from '../../slices/usersApiSlice'
import axios from 'axios'


const UserEditScreen = () => {
    const navigate = useNavigate()
    const {id: userId} = useParams()
    const [email, setEmail] = useState('')
    const [name, setName] = useState('')
   
    const [isAdmin, setIsAdmin] = useState(false)

    const {data:user, isLoading, error, refetch} = useGetUserDetailsQuery()
    const [updateUser, {isLoading: loadingUpdate}] = useUpdateUserMutation()
    

    useEffect(() => {
        if(user){
            setName(user.name)
            setEmail(user.email)
            setIsAdmin(user.isAdmin)
        }
    }
    , [updateUser])

    const submitHandler = async(e) => {
        e.preventDefault()
        try{
            await updateUser({_id: userId, name, email, isAdmin})
            toast.success('User updated successfully')
            navigate('/admin/userlist')
        }catch(err){
            toast.error(err?.data?.message || err.error)
        }

    }

   



  return 
  (
    <>
    <Link to='/admin/userlist' className='btn btn-light my-3'>Go Back</Link>
    <FormContainer>
    <h1>Edit Product</h1>
    {loadingUpdate && <Loader/>}
    {isLoading ? <Loader/> : error ? <Message variant='danger'>{error}</Message> :
    <Form onSubmit={submitHandler}>
        <Form.Group controlId='name'>
            <Form.Label>Name</Form.Label>
            <Form.Control type='name' placeholder='Enter name' value={name} onChange={(e) => setName(e.target.value)}></Form.Control>
        </Form.Group>
        <Form.Group controlId='email'>
            <Form.Label>Email Address</Form.Label>
            <Form.Control type='email' placeholder='Enter email' value={email} onChange={(e) => setEmail(e.target.value)}></Form.Control>
        </Form.Group>
        <Form.Group controlId='isAdmin'>
            <Form.Check type='checkbox' label='Is Admin' checked={isAdmin} onChange={(e) => setIsAdmin(e.target.checked)}></Form.Check>
        </Form.Group>
    
        
        <Button type='submit' variant='primary'>Update</Button>
    </Form>}
    </FormContainer>
    </>
  )

  }

export default UserEditScreen