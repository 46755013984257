import React from "react";
import { Link } from "react-router-dom";
import {
  CheckCorrect,
  ArrowCircleRight,
  ArrowCircleLeft,
  Gift,
} from "@icon-park/react";
import { useGetServicesQuery } from "../../slices/servicesApiSlice";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { useDispatch } from "react-redux";
import { addServiceToCart } from "../../slices/cartSlice";
import { useNavigate } from "react-router-dom";

const WebDevScreen = () => {
  const { data: services, isLoading, error } = useGetServicesQuery();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const addToCartHandler = (service, item) => {
    dispatch(addServiceToCart({ ...item, serviceName: service.name, serviceDescription:service.description, qty: 1 }));
    navigate("/cart");
  };

  return isLoading ? (
    <Loader />
  ) : error ? (
    <Message variant="danger">{error?.data?.message || error.error}</Message>
  ) : (
    <>
      <h1 className="header-webdev">Pricing Plans</h1>
      <div className="container">
        {services.map((service) =>
          service.name === "Web Development"
            ? service.packages.map((item) => (
                <div key={item._id} className="card-webdev">
                  <div className="btn-webdev-top">
                    <h3 className="header-card-webdev">{item.name}</h3>
                  </div>
                  <div className="price-webdev">
                    <span className="price-amount">R {item.price}</span>
                    <span className="price-text">Once-off</span>
                  </div>
                  <ul className="list-card-webdev">
                    {item.features_free.map((feature, index) => (
                      <li key={index}>
                        <Gift theme="outline" size="24" fill="#000" /> {feature}
                      </li>
                    ))}
                    {item.features.map((feature, index) => (
                      <li key={index}>
                        <CheckCorrect theme="outline" size="24" fill="#000" />{" "}
                        {feature}
                      </li>
                    ))}
                  </ul>
                  <button
                    className="btn-outline"
                    onClick={() => addToCartHandler(service, item)}
                  >
                    <Link to="/orderform">Place Order</Link>
                    <ArrowCircleRight theme="outline" size="24" />
                  </button>
                </div>
              ))
            : null
        )}
      </div>
    </>
  );
};

export default WebDevScreen;
