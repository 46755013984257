import React from "react";
import { Link } from "react-router-dom";
import ReactGA from 'react-ga4';

ReactGA.initialize('G-W39W26SYSM'); // Replace with your tracking ID
ReactGA.send("pageview");


const AboutScreen = () => {
  return (
    <main className="about-main">
      {/* <article className="card fade-in">
        <p>
          At Datablox, we are dedicated to leveraging cutting-edge technology to
          empower businesses and individuals, driving innovation and efficiency
          in the digital era. With a passion for delivering exceptional ICT
          services and products, we strive to be a trusted partner for our
          clients, providing tailored solutions to meet their unique needs.
        </p>
      </article> */}
      <article className="card fade-in">
        <h2 className="title">Our Mission</h2>
        <p>
          Our mission is to harness the power of technology to transform
          businesses and enrich lives. We are committed to delivering
          high-quality ICT services and products that enable our clients to
          achieve their goals, enhance productivity, and stay ahead in a rapidly
          evolving digital landscape.
        </p>
      </article>
      <article className="card fade-in">
        <h2 className="title">What We Offer</h2>
        <p>
          <strong>ICT Services:</strong> From IT consulting and system integration to network
          infrastructure and web development, we offer a comprehensive range of
          ICT services designed to optimize performance, minimize risk, and
          ensure seamless operations for our clients.
          <br /><br />
          <strong>Web Development:</strong> We specialise in crafting custom e-commerce and web
          applications that are tailored to our clients' specific requirements.
          Whether it's developing bespoke applications, enterprise software, or
          mobile apps, we have the expertise to bring your vision to life.
          <br /><br />
          <strong>Hardware Solutions:</strong> We provide a wide range of hardware solutions,
          including servers, networking equipment, storage solutions, and
          end-user devices, sourced from leading manufacturers to ensure
          reliability, performance, and scalability.
          <br /><br />
          <strong>Cloud Services:</strong> Embrace the flexibility and scalability of cloud
          computing with our suite of cloud services. From cloud migration and
          infrastructure-as-a-service (IaaS) to software-as-a-service (SaaS)
          solutions, we help businesses leverage the full potential of the cloud
          to drive innovation and growth.
        </p>
      </article>
      <article className="card fade-in">
        <h2 className="title">Why Choose Us</h2>
        <p>
          <strong>Expertise:</strong> Datablox has extensive experience in the ICT industry,
          equipped with the skills and knowledge to deliver superior solutions
          that exceed expectations.
          <br /><br />
          <strong>Innovation:</strong> We place a strong emphasis on technological innovation,
          continuously exploring emerging trends and technologies to deliver
          cutting-edge solutions that address the evolving needs of our clients.
          <br /><br />
          <strong>Customer-Centric Approach:</strong> We prioritize customer satisfaction above all else,
          working closely with our clients to understand their challenges, goals,
          and aspirations, and delivering personalized solutions that drive
          tangible results.
          <br /><br />
          <strong>Quality Assurance:</strong> We are committed to delivering high-quality services and
          products that meet business requirements, industry standards, and best
          practices.
        </p>
      </article>
      <Link to="/contact" className="btn-outline">Get in Touch</Link>
      {/* <p className="fade-in">
        Ready to take your business to the next level with our comprehensive ICT
        services and products? Contact us today to discuss your requirements and
        discover how we can help you achieve your goals.  
      </p> */}
    </main>
  );
};

export default AboutScreen;
