import React, { useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useLogoutMutation } from "../slices/usersApiSlice.js";
import { logout } from "../slices/authSlice.js";
import { ShoppingCart, User,} from "@icon-park/react";
import datablox_logo from "../assets/images/datablox_logo.png";
import bootstrap from "bootstrap/dist/js/bootstrap.bundle.min.js";


const Header = () => {
  const { userInfo } = useSelector((state) => state.auth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [logoutApiCall] = useLogoutMutation();
  const { cartItems } = useSelector((state) => state.cart);
  const offcanvasRef = useRef(null);

  const logoutHandler = async () => {
    try {
      await logoutApiCall().unwrap();
      dispatch(logout());
      navigate("/signin");
    } catch (err) {
      console.log(err);
    }
  };
 
  const handleLinkClick = (e, path) => {
    e.preventDefault(); // Prevent the default link behavior
    navigate(path);
    if (offcanvasRef.current) {
      const offcanvasElement = bootstrap.Offcanvas.getInstance(offcanvasRef.current);
      if (offcanvasElement) {
        offcanvasElement.hide();
      }
  
    }
  };

  return (
    <nav className="navbar navbar-expand-lg bg-body-tertiary container-fluid">
        <div className="logo">
          <Link to="/">
            <img src={datablox_logo} width="150px" height="100px" alt="datablox-logo" />
          </Link>
        </div>

        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="offcanvas"
          data-bs-target="#offcanvasNavbar"
          aria-controls="offcanvasNavbar"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>

        <div
          className="offcanvas offcanvas-end"
          tabIndex="-1"
          id="offcanvasNavbar"
          aria-labelledby="offcanvasNavbarLabel"
          ref={offcanvasRef}
        >
          <div className="offcanvas-header">
            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">
              Menu
            </h5>
            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
          </div>

          <div className="offcanvas-body">
            <ul className="navbar-nav mx-auto mb-2 mb-lg-0">
            <li className="nav-item">
                    <Link className="nav-link active" aria-current="page" to="/" onClick={(e) => handleLinkClick(e, "/")}>
                      Home
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/about" onClick={(e) => handleLinkClick(e, "/about")}>
                      About
                    </Link>
                  </li>
                  <li className="nav-item dropdown">
                    <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                      Services
                    </Link>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" to="/services/webdev" onClick={(e) => handleLinkClick(e, "/services/webdev")}>
                          Web Development
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/services/cloud" onClick={(e) => handleLinkClick(e, "/services/cloud")}>
                          Cloud Services
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/services/networking" onClick={(e) => handleLinkClick(e, "/services/networking")}>
                          Networking
                        </Link>
                      </li>
                      <li>
                        <hr className="dropdown-divider" />
                      </li>
                      <li>
                        <Link className="dropdown-item" to="/services/hardware" onClick={(e) => handleLinkClick(e, "/services/hardware")}>
                          Hardware
                        </Link>
                      </li>
                    </ul>
                  </li>
                  <li className="nav-item">
                    <Link className="nav-link" to="/contact" onClick={(e) => handleLinkClick(e, "/contact")}>
                      Contact
                    </Link>
                  </li>
              {userInfo ? (
                  <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    {userInfo.name}
                  </Link>
                 <ul className="dropdown-menu">
                <li className="nav-item" title={userInfo.name} id="username">
                  <Link to="/profile" className="nav-link" onClick={(e) => handleLinkClick(e, "/profile")}>
                    Profile
                  </Link>
                  <Link className="nav-link" onClick={logoutHandler}>
                    Logout
                  </Link>
                </li>
              </ul>
              </li>
              ) : (
                <>
                 
                </>
              )}
              {userInfo && userInfo.isAdmin && (
                <li className="nav-item dropdown">
                  <Link className="nav-link dropdown-toggle" to="#" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                    Admin Menu
                  </Link>
                  <ul className="dropdown-menu">
                    <li>
                      <Link className="dropdown-item" to="/admin/userlist" onClick={(e) => handleLinkClick(e, "/admin/userlist")}>
                        Users and Resources
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/admin/serviceform" onClick={(e) => handleLinkClick(e, "/admin/serviceform")}>
                        Products and Services
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/admin/salesleads" onClick={(e) => handleLinkClick(e, "/admin/salesleads")}>
                        Sales and Opportunities
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/tasks" onClick={(e) => handleLinkClick(e, "/tasks")}>
                        Operations
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/admin/bmcnotes" onClick={(e) => handleLinkClick(e, "/admin/bmcnotes")}>
                        Strategy
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/admin/docs" onClick={(e) => handleLinkClick(e, "/admin/docs")}>
                        Templates and Docs
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/admin/site_settings" onClick={(e) => handleLinkClick(e, "/admin/site_settings")}>
                        Site Settings
                      </Link>
                    </li>
                  </ul>
                </li>
              )}
            </ul>
          </div>
        </div>

        {/* right nav -cart and user sign-in */}
          {userInfo ? (
            <ul className="right-nav">
              <li>
                <ShoppingCart theme="outline" size="16" fill="#000" />
              </li>
              <li className="nav-item">
                <span onClick={logoutHandler}>
                  <User theme="outline" size="16" fill="#000" />
                </span>
              </li>
            </ul>
          ) : (
              <ul className="right-nav">
                <li className="nav-item">
                  <Link className="nav-link" to="/signin">
                    <User theme="outline" size="16" fill="#000" />
                  </Link>
                </li>
                <div className="cart-container">
                  {cartItems.length > 0 && (
                    <span className="badge-cart-1">{cartItems.length}</span>
                  )}
                  <ShoppingCart theme="outline" size="16" fill="#000" />
                </div>
              </ul>
          )}
    </nav>
  );
};

export default Header;
